/* eslint-disable no-restricted-syntax, guard-for-in */

const DEFAULT_COOKIE_CONSENT = {
  essential: false,
  analytics: false,
};

const COOKIE_CATEGORIES = {
  cookies_policy: 'essential',
  global_bar_seen: 'essential',
  _ga: 'analytics',
};

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

export function setCookie(name, value, options) {
  let cookieString = `${name}=${value}; path=/`;
  if (options.days) {
    const date = new Date();
    date.setTime(date.getTime() + (options.days * MILLISECONDS_IN_A_DAY));
    cookieString = `${cookieString}; expires=${date.toGMTString()}`;
  }
  if (document.location.protocol === 'https:') {
    cookieString += '; Secure';
  }
  document.cookie = cookieString;
}

/**
 * A helper function to parse the cookies.
 * @returns the cookies with valid values as a dictionary.
 */
function parseCookies() {
  const parsedCookies = [{}];
  document.cookie.split(';')
    .filter((keyValuePair) => keyValuePair.indexOf('=') !== keyValuePair.length - 1)
    .forEach((keyValuePairString) => {
      const keyValuePair = keyValuePairString.split('=');
      parsedCookies[keyValuePair[0].trim()] = decodeURIComponent(keyValuePair[1]);
    });
  return parsedCookies;
}

export function getCookie(name) {
  const cookies = parseCookies();
  if (name in cookies) {
    return cookies[name];
  }
  return null;
}

/*
  Cookie methods
  ==============
  Usage:
    Setting a cookie: cookie('global_bar_seen', 'tasty', { days: 90 });
    Reading a cookie: cookie('global_bar_seen');
    Deleting a cookie: cookie('global_bar_seen', null);
*/
export function cookie(name, value, options) {
  let optionsDays = options;
  if (typeof value !== 'undefined') {
    if (value === false || value === null) {
      return setCookie(name, '', { days: -1 });
    }
    // Default expiry date of 90 days
    if (typeof optionsDays === 'undefined') {
      optionsDays = { days: 90 };
    }
    return setCookie(name, value, optionsDays);
  }
  return getCookie(name);
}

export function deleteCookie(cookieName) {
  // Delete the cookie on the current request.
  cookie(cookieName, null);

  // Check if the cookie exists on the .domain.
  if (cookie(cookieName)) {
    const expiryTime = new Date();
    expiryTime.setTime(expiryTime.getTime() - MILLISECONDS_IN_A_DAY);
    const { hostname } = window.location;
    // Clear the value for the cookie on the .domain
    document.cookie = `${cookieName}=;expires=${expiryTime.toGMTString()};domain=.${hostname};path=/`;
  }
}

export function getConsentCookie() {
  const consentCookie = cookie('cookies_policy');
  let consentCookieObj;

  if (consentCookie) {
    try {
      consentCookieObj = JSON.parse(consentCookie);
    } catch (err) {
      return null;
    }

    if (typeof consentCookieObj !== 'object' && consentCookieObj !== null) {
      consentCookieObj = JSON.parse(consentCookieObj);
    }
  } else {
    return null;
  }

  return consentCookieObj;
}

export function setConsentCookie(options) {
  let cookieConsent = getConsentCookie();

  if (!cookieConsent) {
    cookieConsent = JSON.parse(JSON.stringify({ analytics: false }));
  }

  for (const cookieType in options) {
    cookieConsent[cookieType] = options[cookieType];

    // Delete cookies of that type if consent being set to false
    if (!options[cookieType]) {
      for (const cookieName in COOKIE_CATEGORIES) {
        if (COOKIE_CATEGORIES[cookieName] === cookieType) {
          deleteCookie(cookieName);
        }
      }
    }
  }

  setCookie('cookies_policy', JSON.stringify(cookieConsent), { days: 90 });
}

export function setDefaultConsentCookie() {
  setConsentCookie(DEFAULT_COOKIE_CONSENT);
}

export function approveAllCookiesTypes() {
  const approvedConsent = {
    analytics: true,
  };

  setCookie('cookies_policy', JSON.stringify(approvedConsent), { days: 90 });
}

export function deleteUnconsentedCookies() {
  const currentConsent = getConsentCookie();

  for (const cookieType in currentConsent) {
    // Delete cookies of that type if consent being set to false
    if (!currentConsent[cookieType]) {
      for (const cookieName in COOKIE_CATEGORIES) {
        if (COOKIE_CATEGORIES[cookieName] === cookieType) {
          deleteCookie(cookieName);
        }
      }
    }
  }
}
/* eslint-enable no-restricted-syntax, guard-for-in */
